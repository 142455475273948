
import $ from "jquery";

function ImageSwitcher(container, opt) {
    opt = $.extend({
        delay: 500
    }, opt);

    let intervalId = null;
    let currentIndex = 0;

    this.start = function() {
        console.log(container.attr('id'), 'start with index ' + currentIndex);
        let el = container.find('.image-swapper-preview > img');

        let images = [
        ];
        let repo = container.find('.hidden > img');
        repo.each(function(i, item) {
            images.push({
                el: item,
                src: item.src,
                srcset: item.srcset,
                index: parseInt(item.dataset.index)
            });
        });

        intervalId = setInterval(function() {
            if(currentIndex > images.length - 2) {
                currentIndex = 0;
            } else {
                currentIndex++;
            }
            let nextImage = images.find(item => item.index === currentIndex);
            if(nextImage.srcset) {
                el[0].srcset = nextImage.srcset;
            } else {
                el[0].src = nextImage.src;
            }
        }, opt.delay);

        return intervalId;
    };

    this.stop = function() {
        clearInterval(intervalId);
    };
}

export default ImageSwitcher;
